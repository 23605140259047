/* eslint-disable no-undef */
/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import jQuery from 'jquery';
window.jQuery = jQuery;
window['$'] = jQuery;
require('jquery-migrate');

require('jquery.are-you-sure');
require('peity');
require('lodash');

window.bowser = require('bowser');
window.Masonry = require('masonry-layout');
window.Sortable = require('sortablejs');
window.upndown = require('upndown');

// Pikaday
import 'pikaday-responsive/dist/pikaday-package.css';
window.moment = require('moment');
window.Pikaday = require('pikaday');
window.pikadayResponsive = require('pikaday-responsive/dist/pikaday-responsive');
